/**
 * Attention
 * Be aware that this module is dependent on support for Element.prototype.closest
 * This is included as default in https://polyfill.io/v3/polyfill.min.js
 */


// MODALS
const modalOpen = (event) => {
    event.preventDefault();

    const element = event.target;
    const target = document.getElementById(element.getAttribute('data-target'));

    console.log('element', element, target, 'target');

    target.classList.add('js-active');
};

const modalClose = (event) => {
    event.preventDefault();

    const element = event.target;
    const target = element.closest('.modal');

    console.log('element', element, target, 'target');

    target.classList.remove('js-active');
};

/**
 * Initialise video embeds with this function
 * Will only run if given selector elements are found in DOM
 *
 * @param {string} selector - element selector string
 */

export function setupModals() {
  const modalOpenButtons = document.querySelectorAll('[data-action="modal-open"]');
  modalOpenButtons.forEach((button) => {
    button.addEventListener('click', modalOpen);
  });
  const modalCloseButtons = document.querySelectorAll('[data-action="modal-close"]');
  modalCloseButtons.forEach((button) => {
    button.addEventListener('click', modalClose);
  });
}