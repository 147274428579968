/**
 * Attention
 * Be aware that this module is dependent on support for Element.prototype.closest
 * This is included as default in https://polyfill.io/v3/polyfill.min.js
 */

import { addResourceHint, supportsTemplate } from '../utils/helpers';


// VIDEOS
let videoPreconnected = false;

const videoPreload = (element) => {
    if (!videoPreconnected) {
        const videoLink = element.target.closest('[data-action="video-load"]').querySelector('a').href;

        // YOUTUBE ?
        if (videoLink.includes('youtube') || videoLink.includes('youtu.be')) {
            addResourceHint('dns-prefetch', 'https://www.youtube.com');
            addResourceHint('preconnect', 'https://www.youtube.com');
        }

        // VIMEO ?
        if (videoLink.includes('vimeo')) {
            addResourceHint('dns-prefetch', 'https://player.vimeo.com');
            addResourceHint('preconnect', 'https://player.vimeo.com');
        }

        videoPreconnected = true;
    }
};

const videoPlay = (event) => {
    event.preventDefault();

    let isVimeo = false;
    const element = event.target.closest('a');

    let videoURL = '';

    // Get video ID
    const videoLink = element.href;

    // YOUTUBE ?
    let youtubeID = '';
    if (videoLink.includes('youtube')) {
        if (videoLink.includes('embed')) {
            const index = videoLink.lastIndexOf('/');
            youtubeID = videoLink.substring(index + 1);
        } else {
            const index = videoLink.lastIndexOf('v=');
            youtubeID = videoLink.substring(index + 2);
        }
    } else if (videoLink.includes('youtu.be')) {
        const index = videoLink.lastIndexOf('/');
        youtubeID = videoLink.substring(index + 1);
    }
    if (youtubeID) {
        videoURL = 'https://www.youtube.com/embed/' + youtubeID + '?autoplay=1&color=20d18e&modestbranding=1&rel=0&showinfo=0';
    }

    // VIMEO ?
    let vimeoID = '';
    if (videoLink.includes('vimeo')) {
        const index = videoLink.lastIndexOf('/');
        vimeoID = videoLink.substring(index + 1);
    }
    if (vimeoID) {
        isVimeo = true;
        /*videoURL = 'https://player.vimeo.com/video/' + vimeoID + '?byline=0&color=20d18e&portrait=0&title=0&dnt=1';
        const hideControls = element.getAttribute('data-hide-controls').toLowerCase() == 'true';
        const autoPlay = element.getAttribute('data-autoplay').toLowerCase() == 'true';
        const loop = element.getAttribute('data-loop').toLowerCase() == 'true';

        videoURL += hideControls ? '&controls=0' : '';
        videoURL += autoPlay ? '&autoplay=1' : '';
        videoURL += loop ? '&loop=1' : '';*/
    }

    const fragment = document.createDocumentFragment();

    if (!isVimeo) {
        if (supportsTemplate()) {
            const itemsClone = document.querySelector('#video').content.cloneNode(true);

            itemsClone.querySelector('iframe').src = videoURL;
            fragment.appendChild(itemsClone);
        } else {
            const itemElement = document.createElement('iframe');
            itemElement.src = videoURL;

            fragment.appendChild(itemElement);
        }
    }

    element.parentNode.querySelector('.embed-responsive').appendChild(fragment);
    element.parentNode.classList.add('js-video-loaded');
};

/**
 * Initialise video embeds with this function
 * Will only run if given selector elements are found in DOM
 *
 * @param {string} selector - element selector string
 */

export function setupVideos(selector = '[data-action="video-load"] a[href]') {
  window.addEventListener('CookieInformationConsentGiven', (event) => {
    if (CookieInformation.getConsentGivenFor('cookie_cat_marketing')) {
      const videos = document.querySelectorAll(selector);
      videos.forEach((element) => {
        element.addEventListener('click', videoPlay, false);
        element.addEventListener('mouseenter', videoPreload);

        if (element.hasAttribute('data-autoplay')) {
            if (element.getAttribute('data-autoplay').toLowerCase() == 'true') {
                console.log('go ahead and autoplay!');
                element.click();
            }
        }
      });
    }
  }, false);
}