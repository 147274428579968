export const allowStatCookies = window.CookieInformation && CookieInformation.getConsentGivenFor('cookie_cat_statistic');
export const isTouch = 'ontouchstart' in window;
export const isIE11 = !!window.MSInputMethodContext && !!document.documentMode;

/**
 * Sets a custom CSS variable to ensure precise vh unit mesuarment
 *
 */
export function setVhProp(event) {
    // Small delay is needed when orientationchange is triggered
    const delay = event != undefined && event.type == 'orientationchange' ? 100 : 0;
    setTimeout(() => {
        // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
        const vh = window.innerHeight * 0.01;
        // Then we set the value in the --vh custom property to the root of the document
        document.documentElement.style.setProperty('--vh', `${vh}px`);
    }, delay);
}

export function initVhUnitOverwrite() {
    setVhProp();
    window.addEventListener('orientationchange', setVhProp);
}

export function canUseWebP() {
    const elem = document.createElement('canvas');

    if (elem.getContext && elem.getContext('2d')) {
        // was able or not to get WebP representation
        return elem.toDataURL('image/webp').indexOf('data:image/webp') === 0;
    }

    // very old browser like IE 8, canvas not supported
    return false;
}

/**
 * Add a <link rel={preload | preconnect} ...> to the head
 */
export function addResourceHint(type, url, as) {
    const resource = document.createElement('link');

    resource.rel = type;
    resource.href = url;
    if (as) {
        resource.as = as;
    }
    if (type === 'preconnect' || as === 'font') {
        resource.crossorigin = true;
    }

    document.head.appendChild(resource);
};

/**
* Detect if <template> is supported
* @return {Boolean}    True or False
*/
export function supportsTemplate() {
    return 'content' in document.createElement('template');
};

/**
 * Format number sparated with commas per thousand.
 *
 * @param {Number} num - Number you want to format
 *
 * @returns {string} - Returns the number formatet with commas
 *
 * @example:
 * console.info(formatNumber(2665)) // 2,665
 * console.info(formatNumber(102665)) // 102,665
 * console.info(formatNumber(1240.5)) // 1,240.5
 */

export function formatNumber(num, seperator = '.') {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, `$1${seperator}`);
}

/**
 * Prevent function from being executed as long as it is invoked, while given delay hasn't passed.
 *
 * @param  {Function}  callback  Callback
 * @param  {String}    delay     Delay
 * @return {Function}            Callback
 */
export function debounce(callback, delay) {
    let timer = null;

    return function () {
        const context = this,
            args = arguments;

        clearTimeout(timer);

        timer = setTimeout(function () {
            callback.apply(context, args);
        }, delay);
    };
}

/*
* Load JavsScript asynchronously when needed
* @param  {String}    source    The path to the file
* @param  {Function}  callback  The callback to excecute upon load
* @return {Element}             Element to attach
*/
export function loadJS (source, callback) {
    const reference = document.getElementsByTagName('script')[0];
    const script = document.createElement('script');

    script.src = source;
    script.async = true;
    reference.parentNode.insertBefore(script, reference);

    if (callback && typeof(callback) === 'function') {
        script.onload = callback;
    }

    return script;
}

/**
 * Get the thumbnail dimensions to use for a given player size.
 *
 * @param {Object} options
 * @param {number} options.width The width of the player
 * @param {number} options.height The height of the player
 * @return {Object} The width and height
 */
export function getRoundedDimensions({ width, height }) {
    let roundedWidth = width;
    let roundedHeight = height;

    // If the original width is a multiple of 320 then we should
    // not round up. This is to keep the native image dimensions
    // so that they match up with the actual frames from the video.
    //
    // For example 640x360, 960x540, 1280x720, 1920x1080
    //
    // Round up to nearest 100 px to improve cacheability at the
    // CDN. For example, any width between 601 pixels and 699
    // pixels will render the thumbnail at 700 pixels width.
    if (roundedWidth % 320 !== 0) {
        roundedWidth = Math.ceil(width / 100) * 100;
        roundedHeight = Math.round((roundedWidth / width) * height);
    }

    return {
        width: roundedWidth,
        height: roundedHeight
    };
}