import { onReady } from './utils/onReady';
import { isTouch, initVhUnitOverwrite } from './utils/helpers';
import { setupNav } from './components/nav';
import { setupVideos } from './components/video';
import { setupProjectsSliders } from './components/projects-slider';
import { setupIntoView } from './components/intoView';
import { setupModals } from './components/modal';
import { setupProcess } from './components/process';


function init() {
    document.body.classList.remove('standby');

    // Detect touch
    if (isTouch) {
        document.body.classList.add('touch-device');
    }

    onReady(() => {
        document.querySelector('.btn-scroll-up').addEventListener('click', (event) => {
            event.preventDefault();

            window.scrollTo(0,0);
        })

        initVhUnitOverwrite();
        setupNav();
        setupVideos();
        setupIntoView();
        setupModals();
        setupProjectsSliders();
        setupProcess();
    });
}

init();