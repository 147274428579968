/**
 * Attention
 * Be aware that this module is dependent on support for Element.prototype.closest
 * This is included as default in https://polyfill.io/v3/polyfill.min.js
 */

let observer;

const scrollReveal = (event) => {
    const element = (event.target || event);
    const ratio = Math.abs(Math.abs(element.getBoundingClientRect().top / element.getBoundingClientRect().height * 100) - 100) / 100;

    if (ratio > 0.25) {
        element.removeAttribute('data-action');
        elements = document.querySelectorAll(selector);
        element.classList.add('js-in-view');
    }
};

const observerReveal = (event) => {
    const element = event.target;
    const ratio = event.intersectionRatio;

    if (ratio > 0.25) {
        observer.unobserve(element);
        element.classList.add('js-in-view');
    }
};

const onIntersection = (elements) => {
    elements.forEach((element) => {
        observerReveal(element);
    });
};


/**
 * Initialise video embeds with this function
 * Will only run if given selector elements are found in DOM
 *
 * @param {string} selector - element selector string
 */

export function setupIntoView(selector = '[data-action="into-view"]') {
    let elements = document.querySelectorAll(selector);

    if (!('IntersectionObserver' in window)) {
        let ticking = false;

        window.addEventListener('scroll', () => {
            if (!ticking) {
                requestAnimationFrame(() => {
                    ticking = false;
                    elements.forEach((element) => {
                        if (element.getBoundingClientRect().top < window.innerHeight && element.getBoundingClientRect().top + element.getBoundingClientRect().height > 0.25) {
                        scrollReveal(element);
                        }
                    });
                });
            }
            ticking = true;
        }, false);
    } else {
        observer = new IntersectionObserver(onIntersection, {
            rootMargin: '0px 0px',
            threshold: [0.25]
        });

        elements.forEach((element) => {
            observer.observe(element);
        });
    }
}