export function setupProcess() {
    const modules = document.querySelectorAll('.module.process');

    if(modules && modules.length > 0) {
        Array.from(modules).forEach(module => {
            setupScroll(module);
        })
    }
}

function setupScroll(module) {
    const navigation = module.querySelector('.process__navigation');
    const items = Array.from(module.querySelectorAll('[data-step]'));

    const itemsOptions = {
        threshold: 1.0
    };

    const itemsIO = new IntersectionObserver(entries => {
        entries.forEach(entry => {
            if(entry.isIntersecting) {
                const step = entry.target.getAttribute('data-step');

                navigation.querySelector('.js-active').classList.remove('js-active');
                navigation.querySelector(`[href="#process-${step}"]`).parentElement.classList.add('js-active');
            }
        });
    }, itemsOptions);
        
    items.forEach(item => {
        itemsIO.observe(item);
    })
}