import Swiper, { Navigation, Pagination } from 'swiper';
import { scrollTop } from '../utils/scroll';

export function setupProjectsSliders() {
    const sliders = document.querySelectorAll('.projects-slider__swiper');

    if(sliders) {
        Array.from(sliders).forEach(slider => {
            const container = slider.parentNode;
            const cursor = container.querySelector('.projects-slider__custom-cursor');
            const swiper = new Swiper(slider, {
                modules: [Navigation, Pagination],
                slidesPerView: 1.5,
                spaceBetween: 10,
                breakpoints: {
                    768: {
                        slidesPerView: 2.325,
                        spaceBetween: 20,
                    },
                    992: {
                        slidesPerView: 2.325,
                        spaceBetween: 40,
                    }
                },
                navigation: {
                    nextEl: '.swiper-button-next',
                    prevEl: '.swiper-button-prev',
                },
                pagination: {
                    el: '.swiper-pagination',
                    type: 'progressbar'
                }
            })

            const canHover = window.matchMedia('(hover: hover)').matches;

            if(canHover) {
                container.addEventListener('mouseenter', event => {
                    cursor.classList.add('active');
                })
        
                container.addEventListener('mouseleave', event => {
                    cursor.classList.remove('active');
                })
    
                container.addEventListener('pointermove', event => {
                    let x = event.clientX;
                    let y = event.clientY;
    
                    let rect = container.getBoundingClientRect();
    
                    y = y - rect.top;
                    
                    cursor.style.top = `${y}px`;
                    cursor.style.left = `${x}px`;
                })
    
                slider.addEventListener('mouseenter', event => {
                    cursor.classList.add('highlight');
                })
    
                slider.addEventListener('mouseleave', event => {
                    cursor.classList.remove('highlight');
                })
            } else {
                cursor.remove();
            }
        })        
    }    
}